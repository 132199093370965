/* eslint-disable unused-imports/no-unused-imports */
import { PieChart, TableChart } from "@material-ui/icons";
import { baseHost } from "utils/apis";
import { UniqueCode } from "utils/events";
import { urls } from "./../utils/utils";

const uniqId = `L${UniqueCode(1000000)}`;
export const WalletSingleModel = {
  table: "res_partner",
  transform: "currency",
  field: "wallet",
  icon: PieChart,
  color: "bgWarning",
  footerText: "Balance",
  placeholder: "0.00",
  desc: "Wallet Card Model",
  title: "Wallet",
};

export const PostpaidTransactionSingleModel = {
  table: "res_partner",
  transform: "currency",
  field: "e_consumed",
  icon: TableChart,
  color: "bgWarning",
  footerText: "Billed Units",
  placeholder: "0.00",
  desc: "Wallet Card Model",
  title: "Total Consumption",
  name: "id.e_consumed#feeder_customer_details#acc_no",
  control: "render_consumption_card",
  fields: [
    {
      co_name: "",
      name: "id.e_consumed#feeder_customer_details#acc_no",
    },
  ],
};

export const LastPaymentModel = {
  table: "res_partner",
  transform: "currency",
  field: "amount",
  icon: TableChart,
  color: "bgWarning",
  footerTextField: "payment_date",
  footerText: "",
  footerTextTransform: "date",
  placeholder: "0.00",
  desc: "Last Payment Model",
  title: "Last Payment",
  fields: [
    {
      field_name: "amount",
      name: "id.amount#account_payment#partner_id",
    },
    {
      table_name: "account_payment",
      name: "payment_date",
    },
  ],
  where: "",
  limit: 1,
  opts: {
    sort: 'desc',
    order: 'payment_date::date',
  }
  // control: 'http_action',
  // // request: {
  // //   url: '/get/last_payment',
  // //   data: '',
  // // },
  // request: {
  //   url: urls.postpaidLastPayment,
  //   method: "post",
  //   baseUrl: baseHost + "/",
  //   data: {
  //     where: ''
  //   }
  // },
};

export const ArrearsSingleModel = {
  table: "res_partner",
  transform: "currency",
  field: "new_arrears",
  icon: TableChart,
  color: "bgWarning",
  footerText: "Arrears",
  placeholder: "0.00",
  desc: "Arrears Model",
  title: "Arrears",
};

export const SubaccountSingleModel = {
  table: "stage_api",
  transform: "currency",
  field: "total_debt",
  icon: TableChart,
  color: "bgWarning",
  footerText: "Sub account ballance",
  placeholder: "0.00",
  desc: "Sub account Model",
  title: "Sub account ballance",
};
