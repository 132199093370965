
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import theme from "assets/theme/theme.js";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/print.css";

import Invoice from "./components/receipt/receipt";
import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouteNotFound from "components/receipt/RouteNotFound";
import Login from "./Login";
import { logOut } from "./utils/utils";
import VendorLogin from "VendorLogin";
import { createBrowserHistory } from "history";
// import IdleTimer from 'react-idle-timer';

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

const App = () => {
  const CheckforinstantActivity = () => {
    const Epiretime = window.localStorage.getItem('timer');
    const token = window.localStorage.getItem('timer');
    if (Epiretime < Date.now() && localStorage.getItem('userData') !== null ) {
      // Perform logout actions (e.g., clear localStorage)
      logOut();
      // window.localStorage.clear();
      // history.push('/web-login');
      // history.go(0);
      // window.location.reload();
    }

  };
  const UpdateEpiresTime = () => {
    const Expiretimecreate = Date.now() + 20000;
    window.localStorage.setItem('timer', Expiretimecreate);

  };

  // useEffect(() => {
  //   const internal = setInterval(() => {
  //     CheckforinstantActivity();
  //   },9000)

  //   return () => clearInterval(internal);
  // },[]);

  useEffect(() => {
    const interval = setInterval(() => {
      CheckforinstantActivity();
    }, 12 * 60 * 1000); // 30 minutes interval
  
    return () => clearInterval(interval);
  }, []);
  
  useEffect(() => {
    UpdateEpiresTime()
    window.addEventListener("click",UpdateEpiresTime);
    window.addEventListener("keypress",UpdateEpiresTime);
    window.addEventListener("scroll",UpdateEpiresTime);
    window.addEventListener("mousemove",UpdateEpiresTime);

    return () =>{
      window.addEventListener("click",UpdateEpiresTime);
      window.addEventListener("keypress",UpdateEpiresTime);
      window.addEventListener("scroll",UpdateEpiresTime);
      window.addEventListener("mousemove",UpdateEpiresTime);
    }

  },[]);

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />

          <Route path="/receipt"  component={() => <Invoice />} />
          <Route path="/customers"  component={() => <Login />} />
          <Route path="/web-login"  component={() => <Login />} />
          <Route path="/vendor-login"  component={() => <VendorLogin />} />
          <Route
            path="/web-logout"
            component={() => {
              logOut();
            }}
          />
          <Route
            path="/notfound/:caller/:requester"
            component={() => <RouteNotFound />}
          />
          <Redirect from="/" to="/web-login" />
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </ThemeProvider>
  );
};

ReactDOM.render(<App />, document.querySelector("#root"));
