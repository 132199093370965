import moment from "moment";
import { toast } from "react-toastify";
import { httpRequest } from "./apis";
import {
  clearLocalStorage,
  getFromLocalStorage,
  removeFromLocalStorage,
} from "./localStorage.util";
import { roles } from "./../permissions";

export const managementDashboardUrls = {
  baseURL:
    `${process.env.REACT_APP_ManagementDashboardBaseUrl}` ||
    "http://188.166.99.136:7010/",
  countArrearsByCustomerClass: ({ startDate, endDate }) =>
    `transactions/count/arrears-customer-class/${startDate}/${endDate}`,
  sumArrearsByCustomerClass: ({ startDate, endDate }) =>
    `transactions/sum/arrears-customer-class/${startDate}/${endDate}`,
  countArrearsByCustomerCategory: ({ startDate, endDate }) =>
    `transactions/count/arrears-by-customer-category/${startDate}/${endDate}`,
  sumArrearsByCustomerCategory: ({ startDate, endDate }) =>
    `transactions/sum/arrears-by-customer-category/${startDate}/${endDate}`,
  sumArrearsTransaction: ({ startDate, endDate }) =>
    `transactions/sum/arrears/${startDate}/${endDate}`,
  countArrearsTransaction: ({ startDate, endDate }) =>
    `transactions/count/arrears/${startDate}/${endDate}`,
  sumCashTransaction: ({ startDate, endDate }) =>
    `transactions/sum/cash/${startDate}/${endDate}`,
  countCashTransaction: ({ startDate, endDate }) =>
    `transactions/count/cash/${startDate}/${endDate}`,
  sumReversalTransaction: ({ startDate, endDate }) =>
    `transactions/sum/reversal/${startDate}/${endDate}`,
  countReversalTransaction: ({ startDate, endDate }) =>
    `transactions/count/reversal/${startDate}/${endDate}`,
  sumWalletTransaction: ({ startDate, endDate }) =>
    `transactions/sum/wallet/${startDate}/${endDate}`,
  countWalletTransaction: ({ startDate, endDate }) =>
    `transactions/count/wallet/${startDate}/${endDate}`,
  sumPrepaidTransactionByType: ({ startDate, endDate }) =>
    `transactions/sum/transaction-by-type/${startDate}/${endDate}`,
  countPrepaidTransactionByType: ({ startDate, endDate }) =>
    `transactions/count/transaction-by-type/${startDate}/${endDate}`,
  sumPrepaidTransaction: ({ startDate, endDate }) =>
    `transactions/sum/${startDate}/${endDate}`,
  countPrepaidTransaction: ({ startDate, endDate }) =>
    `transactions/count/${startDate}/${endDate}`,
  totalPrepaidCustomer: `general/totalPrepaid/`,
  totalActivePostpaid: `general/totalActivePostpaid/`,
  totalSuspendedPostpaid: `general/totalSuspendedPostpaid/`,
  totalCustomers: `general/totalCustomers/`,
  totalUnMeteredCustomer: `general/totalUnMeteredCustomer/`,
  totalCustomersByCustomerClass: `customer/totalCustomersByCustomerClass/`,
  totalCustomersByCustomerCategory: `customer/totalCustomersByCustomerCategory/`,
  totalAssignedPrepaidMeters: `meters/total-assigned-prepaid-meters/`,
  totalAssignedPrepaidMetersByCustomerCategory: `meters/total-assigned-prepaid-meters-customer-category/`,
  totalAssignedPrepaidMetersByCustomerClass: `meters/total-assigned-prepaid-meters-customer-class/`,
  totalUnassignedMeters: `meters/total-unassigned-meters/`,
  totalCollectionByCustomersCategory: ({ start, end }) =>
    `payments/totalCollectionByCustomers/${start}/${end}`,
  totalCollectionByBillType: ({ start, end }) =>
    `payments/totalCollectionByBillType/${start}/${end}`,
  totalPrepaidCustomersByServiceCenter: `customer/totalPrepaidCustomersByServiceCenters`,
  totalActivePostpaidCustomersByServiceCenter: `customer//totalActivePostpaidCustomersByServiceCenters`,
  totalSuspendedPostpaidCustomersByServiceCenter: `customer/totalSuspendedPostpaidCustomersByServiceCenters`,
  totalActivePostPaidCustomersByTariff: `customer/totalActivePostPaidCustomersByTariff`,
  totalSuspendedPostPaidCustomersByTariff: `customer/totalSuspendedPostPaidCustomersByTariff`,
  totalPrePaidCustomersByTariff: `customer/totalPrePaidCustomersByTariff`,
  totalActivePostPaidCustomersByCustomerCategory: `customer/totalActivePostPaidCustomersByCustomerCategory`,
  totalSuspendedPostPaidCustomersByCustomerCategory: `customer/totalSuspendedPostPaidCustomersByCustomerCategory`,
  totalPrePaidCustomersByCustomerCategory: `customer/totalPrePaidCustomersByCustomerCategory`,
  countPrepaidTransactionMonthly: ({ startDate, endDate }) => `transactions/count/prepaid-transaction-monthly/${startDate}/${endDate}`,
  sumPrepaidTransactionMonthly: ({ startDate, endDate }) => `transactions/sum/prepaid-transaction-monthly/${startDate}/${endDate}`,
  countPrepaidTransactionWeekly: ({ startDate, endDate }) => `transactions/count/prepaid-transaction-weekly/${startDate}/${endDate}`,
  sumPrepaidTransactionWeekly: ({ startDate, endDate }) => `transactions/sum/prepaid-transaction-weekly/${startDate}/${endDate}`,
  totalActivePostpaidCustomersByServiceCenters: `customer/totalActivePostpaidCustomersByServiceCenters/`,
  totalSuspendedPostpaidCustomersByServiceCenters: `customer/totalSuspendedPostpaidCustomersByServiceCenters/`,
  totalPrepaidArrearsByServiceCenters: `arrears/totalPrepaidArrearsByServiceCenters`,
  totalActivePostpaidArrearsByServiceCenters: `arrears/totalActivePostpaidArrearsByServiceCenters`,
  totalSuspendedPostpaidArrearsByServiceCenters: `arrears/totalSuspendedPostpaidArrearsByServiceCenters`,
  totalPrepaidArrearsByTariff: `arrears/totalPrepaidArrearsByTariff`,
  totalActivePostpaidArrearsByTariff: `arrears/totalActivePostpaidArrearsByTariff`,
  totalSuspendedPostpaidArrearsByTariff: `arrears/totalSuspendedPostpaidArrearsByTariff`,
  totalPrepaidArrearsByCustomerCategory: `arrears/totalPrepaidArrearsByCustomerCategory`,
  totalSuspendedPostpaidArrearsByCustomerCategory: `arrears/totalSuspendedPostpaidArrearsByCustomerCategory`,
  totalActivePostpaidArrearsByCustomerCategory: `arrears/totalActivePostpaidArrearsByCustomerCategory`,

};

export const smartCashAnalyticsDashboardUrls = {
  baseURL: `${process.env.REACT_APP_SmartCashBaseUrl}`,
  sumTransactionsByBillType: ({ startDate, endDate }) =>
    `analytics/sumTransactionsByBillType/${startDate}/${endDate}`,
  sumTransactionsByPurpose: ({ startDate, endDate }) =>
    `analytics/sumTransactionsByPurpose/${startDate}/${endDate}`,
  sumTransactionsByPaymentMode: ({ startDate, endDate }) =>
    `analytics/sumTransactionsByPaymentMode/${startDate}/${endDate}`,
  sumTransactionsByChannel: ({ startDate, endDate }) =>
    `analytics/sumTransactionsByChannel/${startDate}/${endDate}`,
  sumArrearsTransactionsByBillType: ({ startDate, endDate }) =>
    `analytics/sumArrearsTransactionsByBillType/${startDate}/${endDate}`,
  sumArrearsTransactionsByPurpose: ({ startDate, endDate }) =>
    `analytics/sumArrearsTransactionsByPurpose/${startDate}/${endDate}`,
  sumArrearsTransactionsByPaymentMode: ({ startDate, endDate }) =>
    `analytics/sumArrearsTransactionsByPaymentMode/${startDate}/${endDate}`,
  sumArrearsTransactionsByChannel: ({ startDate, endDate }) =>
    `analytics/sumArrearsTransactionsByChannel/${startDate}/${endDate}`,
  sumArrearsTransactions: ({ startDate, endDate }) =>
    `analytics/sumArrearsTransactions/${startDate}/${endDate}`,
  sumTransactions: ({ startDate, endDate }) =>
    `analytics/sumTransactions/${startDate}/${endDate}`,
};

export const urls = {
  generateBillingReceiptBaseUrl:
    `${process.env.REACT_APP_BillingReceiptBaseUrl}` ||
    "",
  generateBillingReceipt: ({ id, month, year, category, fileName }) =>
    `download_now_real/?type=customers&id=${id}&month=${month}&year=${year}&category=${category}&name_of_file=${fileName}.pdf`,
  assignMeter: (bulk) => `assign/meter/${!bulk ? "" : "?bulk=1"}`,
  auditReport: (args) =>
    "report/audit/?start_date=" +
    (args.startDate || "") +
    "&end_date=" +
    (args.endDate || "") +
    "&action=" +
    (args.action || "") +
    "&report=" +
    (args.report || ""),
  supportTicket: "support-ticket/",
  vendors: "vendor/vendors/",
  vendorSignup: "vendor/new/",
  vendorOwner: "vendor/vendor/owner",
  vendorUser: "vendor/vendor/user",
  reportAdjustment: "report/adjustment/",
  vendorSignin: "vendor/signin/",
  internalVendor: "vendors/internal/",
  externalVendor: "vendors/external/",
  prepaidReport: "report/prepaid-report/",
  vendorFund: "vendors/fund/",
  vendorWithdrawal: "vendors/withdraw/",
  pendingVendors: "vendors/pending/",
  actionOnNewVendor: `vendor/action/`,
  localListVendor: "vendors/local-list/",
  // vendorTransaction: "vendor/report/",
  vendorTransaction: "newvendor/report",
  vendorTransactionReportFormFields: "report/get-form-fields",
  generateVendorTransactionReport: "report/transaction",
  postpaidLastPayment: 'get/last_payment',
  vendorChangePassword: "vendors/change-password",
  prepaidReportFormFields: "prepaid-report/get-form-fields",
  generatePrepaidTransactionReport: "prepaid-report/generate",
  arrearsVend: 'arrears-vend',
  postpaidReportTypes: 'report/postpaid/report-types',
  generateFeederReport: "prepaid-feeder-report/generate",
  transactionReversal: "transaction-reversal",
  getAllPendingMeterRequest: "get-all-pending-customer-info",
  approveMeterRequest: "approve-customer-info",
  finalizeMeterRequest: "finalize-customer-info",
  getPendingCustomerInfoById: ({ id }) => `get-pending-customer-info-by-id?id=${id}`,
  internalTransactionReversal: "internal-transaction-reversal",
};

export const appStrings = {
  appTitle: "SMARTPOWER-BILLING",
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const zeroPad = (num, places) => String(num).padStart(places, '0');

export const prepareValue = (transform, value) => {
  if (transform) {
    let _value = undefined;
    switch (transform.toLowerCase()) {
      case "number":
        value = numberWithCommas(value);
        break;
      case "currency":
        value = parseFloat(value).toFixed(2);
        value = numberWithCommas(value);
        break;
      case "tofixed":
        value = parseFloat(value).toFixed(2);
        break;
      case "moment":
      case "date":
        _value = moment(value).utc().add(1, 'hour').format("yyyy/MM/DD");
        if (_value !== "Invalid date") {
          value = _value;
        };
        break;
      case "datetime":
        _value = moment(value).utc().add(1, 'hour').format("yyyy/MM/DD hh:mm");
        if (_value !== "Invalid date") {
          value = _value;
        };
        break;
      case "zeropad":
        value = zeroPad(value, 2);
      default:
        break;
    }
  }
  return value;
};

export const generateKey = (pre) => {
  const _date = new Date();
  if (!pre) pre = `${uuidv4()}`;
  return `${pre}`;
};

export const prepareParamCaller = (value) => {
  if (value === "meter") value = "meter_no";
  return value;
};

export function uuidv4() {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function spaceOutCharacters(text) {
  let matches = text.match(/.{1,4}/g);
  return matches.join(" ");
}

export function extractValue(field) {
  return field.split("#")[0].split(".")[1];
}

export const bandIds = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E",
  6: "F",
  7: "8",
};

export function PrepareData(Data, type = "json") {
  //change default based on app's api default content type
  if (type === "json") {
    return JSON.stringify(Data);
  } else if (type === "multipart") {
    const formData = new FormData();
    Object.keys(Data).forEach((e) => {
      formData.append(e, Data[e]);
    });
    return formData;
  }
}

export function base64ToArrayBuffer(base64) {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export function saveByteArray(reportName, byte, extension = ".pdf") {
  // var blob = new Blob([byte], {type: applicationType});
  // var link = document.createElement('a');
  // link.href = window.URL.createObjectURL(blob);
  // var fileName = reportName;
  // link.download = fileName;
  // link.click();
  if (!extension.startsWith(".")) {
    extension = ".";
  }

  const url = window.URL.createObjectURL(new Blob([byte]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `${reportName}${new Date().toLocaleTimeString()}${extension}`
  ); //or any other extension
  document.body.appendChild(link);
  link.click();
}

export function showToast({ type, message }) {
  if (type === "error") toast.error(message);
  if (type === "success") toast.success(message);
  return;
}

export const getRoles = () => {
  let roles = null;

  let rawData = getFromLocalStorage("roles");
  console.log("rawData", rawData);

  if (rawData) {
    roles = rawData;
  }

  return roles;
};

export const getUserName = () => {
  let username = null;

  let rawData = getFromLocalStorage("userData");
  console.log("rawData", rawData);

  if (rawData) {
    username = rawData.username;
  }

  return username;
};

export function getParam(name) {
  if (typeof window !== "undefined") {
    if (
      (name = new RegExp("[?&]" + encodeURIComponent(name) + "=([^&]*)").exec(
        window.location.search
      ))
    )
      return decodeURIComponent(name[1]);
  }
}

export async function logOut() {
  try {
    await httpRequest({ url: "signout/", method: "post", data: {} });
    removeFromLocalStorage("userData");
    removeFromLocalStorage("roles");
    clearLocalStorage();
    window.location.href = "/web-login";
  } catch (e) {
    console.log(e);
  }
}

export const monthStartAndEnd = (params) => {
  let _moment = moment();
  let _moment2 = moment();
  if (params) {
    const { startDate, endDate } = params;
    if (typeof startDate !== undefined) {
      _moment = moment(startDate);
    }
    if (typeof endDate !== undefined) {
      _moment2 = moment(endDate);
    }
  }

  return {
    start: _moment.startOf("month").format("YYYY-MM-DD"),
    end: _moment2.endOf("month").format("YYYY-MM-DD"),
  };
};

export const yearStartAndEnd = (params) => {
  let _moment = moment();
  let _moment2 = moment();
  if (params) {
    const { startDate, endDate } = params;
    if (typeof startDate !== undefined) {
      _moment = moment(startDate);
    }
    if (typeof endDate !== undefined) {
      _moment2 = moment(endDate);
    }
  }

  return {
    start: _moment.startOf("year").format("YYYY-MM-DD"),
    end: _moment2.endOf("year").format("YYYY-MM-DD"),
  };
};

export const weekStartAndEnd = (params) => {
  let _moment = moment();
  let _moment2 = moment();
  if (params) {
    const { startDate, endDate } = params;
    if (typeof startDate !== undefined) {
      _moment = moment(startDate);
    }
    if (typeof endDate !== undefined) {
      _moment2 = moment(endDate);
    }
  }

  return {
    start: _moment.startOf("week").format("YYYY-MM-DD"),
    end: _moment2.endOf("week").format("YYYY-MM-DD"),
  };
};

export const quarterStartAndEnd = (params) => {
  let _moment = moment();
  let _moment2 = moment();
  if (params) {
    const { startDate, endDate } = params;
    if (typeof startDate !== undefined) {
      _moment = moment(startDate);
    }
    if (typeof endDate !== undefined) {
      _moment2 = moment(endDate);
    }
  }

  return {
    start: _moment.startOf("quarter").format("YYYY-MM-DD"),
    end: _moment2.endOf("quarter").format("YYYY-MM-DD"),
  };
};

export const dailyStartAndEnd = (params) => {
  let _moment = moment();
  let _moment2 = moment();
  if (params) {
    const { startDate, endDate } = params;
    if (typeof startDate !== undefined) {
      _moment = moment(startDate);
    }
    if (typeof endDate !== undefined) {
      _moment2 = moment(endDate);
    }
  }

  return {
    start: _moment.startOf("daily").format("YYYY-MM-DD"),
    end: _moment2.endOf("daily").format("YYYY-MM-DD"),
  };
};

export const checkUserRoleAccess = ({ access, userRoles }) => {
  if (access && userRoles) {
    if (userRoles.length > access.length) {
      // if user has more roles then check if userRoles any of prop.access roles
      if (!userRoles.some((r) => access.indexOf(r) >= 0)) {
        return false;
      }
    } else {
      // if user has less roles then check if prop.access any of userRoles roles
      if (!access.some((r) => userRoles.indexOf(r) >= 0)) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export async function pageSwitcher() {
  try {
    const userRoles = getRoles();
    console.log("userRoles", userRoles, userRoles.indexOf(roles.guest));
    if (userRoles) {
      if (userRoles.indexOf(roles.guest) !== -1) {
        setTimeout(() => {
          showToast({
            message: 'Guest Role not supported',
            type: 'error',
          });
          window.location.href = "/web-logout";
        }, 2000);

        return true;
      } else if (userRoles.indexOf(roles.auditors) !== -1) {
        window.location.href = "/admin/audit-report";
        // history.push("/admin/audit-report", {
        //   pathname: `/admin/audit-report`,
        //   state: "",
        // });
        return;
      }
    }

    window.location.href = "/admin/index";
    // history.push("/admin/index", {
    //   pathname: `/admin/index`,
    //   state: "",
    // });
  }
  catch (e) {
    console.log("EX", e.toString());
  }
}