/* eslint-disable unused-imports/no-unused-imports */
import React from "react";
import { TableBody, TableRow, TableCell } from "@material-ui/core";
import {
  //Link,
  useHistory,
  useParams,
} from "react-router-dom";
// import Box from "@material-ui/core/Box";
// import Avatar from "@material-ui/core/Avatar";

// import { LinearProgress } from '@material-ui/core';

import componentStyles from "assets/theme/views/admin/tables.js";
import { makeStyles } from "@material-ui/core/styles";
import { OpenInNewOffRounded } from "@mui/icons-material";

// @material-ui/icons components
// import MoreVert from "@material-ui/icons/MoreVert";
//import { FormControlLabel, Checkbox } from "@material-ui/core";
import {
  generateKey,
  prepareParamCaller,
  prepareValue,
} from "./../utils/utils";

const useStyles = makeStyles(componentStyles);
function FetchTableBody({
  cols = [],
  records = [],
  control = undefined,
  form = undefined,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { caller, requester } = useParams();

  const handleChange = (evt) => {
    evt.currentTarget.setAttribute("checked", !evt.currentTarget.checked);
  };

  const processPDF = (data) => {
    data = {
      ...data,
      ...{
        action: "download_pdf",
        caller: prepareParamCaller(caller),
        requester,
      },
    };
    history.push({
      pathname: `/receipt` /* this path field is based on your project */,
      state: data /* pass state data to app page */,
    });
  };

  const selfLinkNavigate = (e, data) => {
    if (data.metering_type && data.metering_type.toLowerCase() === "postpaid") {
      e.preventDefault();
      history.push({
        pathname: `${cols[0].postpaidLink}${
          data[cols[0].name]
        }` /* this path field is based on your project */,
        state: data /* pass state data to app page */,
      });
    } else if (
      data.metering_type &&
      data.metering_type.toLowerCase() === "prepaid"
    ) {
      e.preventDefault();
      history.push({
        pathname: `${cols[0].link}${
          data[cols[0].name]
        }` /* this path field is based on your project */,
        state: data /* pass state data to app page */,
      });
    }
  };

  const selfLinkNavigatevendor = (e, data) => {
      e.preventDefault();
      history.push({
        pathname: `${cols[0].vendorLink}${
          data[cols[0].name]
        }` /* this path field is based on your project */,
        state: data /* pass state data to app page */,
      });
  };
  const constructRow = (cols, cus) => {
    const dataObj = {};
    cols.map((column, colindx) => {
      if (column.co_name !== undefined) {
        dataObj[column.co_name] = cus[column.co_name];
      } else {
        dataObj[column.name] = cus[column.name];
      }
    });

    return dataObj;
  };

  let ActionComponent = undefined;

  if (form && form.action && form.action.component) {
    // think of action compo as normal parent compo
    ActionComponent = form?.action?.component;
    if (Array.isArray(ActionComponent)) {
      const tmpActionComponent = ActionComponent;
      ActionComponent = ({ data }) => {
        return (
          <>
            {tmpActionComponent.map((Element, index) => {
              return (
                <>
                  <TableCell
                    key={generateKey()}
                    classes={{
                      root: classes.tableCellRoot,
                    }}
                  >
                    <Element key={index} data={data} history={history} />
                  </TableCell>
                </>
              );
            })}
          </>
        );
      };
    }
    console.log("control", control, typeof form, ActionComponent);
  }

  return (
    <TableBody>
      {records.length > 0 &&
        records.map((cus, indx) => (
          <TableRow key={indx}>
            {/* <TableCell
            key={`check${indx}`}
            classes={{
              root: classes.tableCellRoot,
            }}
          >
            <FormControlLabel
              control={<Checkbox onChange={handleChange} key={indx} />}
            />
          </TableCell> */}
            {cols.map((column, colindx) => {
              if (
                cus[column.name] !== undefined ||
                column.co_name !== undefined
              ) {
                switch (column.type) {
                  case "selflink":
                    return (
                      <TableCell
                        key={`cell${colindx}`}
                        classes={{
                          root: classes.tableCellRoot,
                        }}
                      >
                        <a
                          href={`${column.link}${
                            column.co_name !== undefined
                              ? cus[column.co_name]?.toString()
                              : cus[column.name]?.toString()
                          }`}
                          className={classes.logoLinkClasses}
                          onClick={(e) => {
                            selfLinkNavigate(e, constructRow(cols, cus));
                          }}
                        >
                     
                          {column.co_name !== undefined
                            ? prepareValue(
                                column.transform,
                                cus[column.co_name]?.toString()
                              )
                            : prepareValue(
                                column.transform,
                                cus[column.name]?.toString()
                              )}
                        </a>
                        <a
                          href={`${column.link}${
                            column.co_name !== undefined
                              ? cus[column.co_name]?.toString()
                              : cus[column.name]?.toString()
                          }`}
                          className={classes.logoLinkClasses}
                          onClick={(e) => {
                            selfLinkNavigatevendor(e, constructRow(cols, cus));
                          }}
                        >
                    
                        </a>
                        {/* <Link
                        to={`${column.link}${
                          column.co_name !== undefined
                            ? cus[column.co_name]?.toString()
                            : cus[column.name]?.toString()
                        }`}
                        className={classes.logoLinkClasses}
                        
                      >
                        {column.co_name !== undefined
                          ? cus[column.co_name]?.toString()
                          : cus[column.name]?.toString()
                        }
                      </Link> */}
                      </TableCell>
                    );
                  case "hidden":
                    return <></>;
                  default:
                    return (
                      <TableCell
                        key={`cell${colindx}`}
                        classes={{
                          root: classes.tableCellRoot,
                        }}
                      >
                        {column.co_name !== undefined
                          ? prepareValue(column.transform, cus[column.co_name])
                          : prepareValue(column.transform, cus[column.name])}
                      </TableCell>
                    );
                }
              } else {
                return (
                  <TableCell
                    key={`cell${colindx}`}
                    classes={{
                      root: classes.tableCellRoot,
                    }}
                  ></TableCell>
                );
              }
            })}
            {control && control === "prepaid_transaction_table" && (
              <TableCell
                key={generateKey()}
                classes={{
                  root: classes.tableCellRoot,
                }}
              >
                {control && control === "prepaid_transaction_table" && (
                  <OpenInNewOffRounded
                    onClick={() => {
                      const dataObj = constructRow(cols, cus);
                      processPDF(dataObj);
                    }}
                  />
                )}
              </TableCell>
            )}
            {/*  */}
            {ActionComponent !== undefined && (
              <>
                <ActionComponent data={cus} />
              </>
            )}
            {/* <TableCell
        classes={{
          root: classes.tableCellRoot,
        }}
      >
 
      </TableCell>
          */}

            {/* <TableCell
        classes={{ root: classes.tableCellRoot }}
        align="right"
      >
        <Box
          aria-controls="simple-menu-5"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          rowid={indx}
          key={indx}
          component={Button}
          width="2rem!important"
          height="2rem!important"
          minWidth="2rem!important"
          minHeight="2rem!important"
        >
          <Box
            component={MoreVert}
            width="1.25rem!important"
            height="1.25rem!important"
            position="relative"
            top="2px"
            color={theme.palette.gray[500]}
          />
        </Box>
        
      </TableCell>  */}
          </TableRow>
        ))}
      {/* */}
    </TableBody>
  );
}

export default FetchTableBody;
