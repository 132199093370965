/* eslint-disable unused-imports/no-unused-imports */
import Header from "./../../components/Headers/Header";
import FetchTable from "../Tables/FetchTable";
import React, { useState, CSSProperties } from "react";
import { VendorsTransactionsModel } from "../../model/vendors/vendor_transactions_model"
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { httpRequest } from "../../utils/apis"
import { showToast } from "utils/utils";
import { saveByteArray } from "utils/utils";
import { base64ToArrayBuffer } from "utils/utils";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, makeStyles, useTheme } from "@material-ui/core";
import CustomerTableHead from "../../views/admin/CusTableHead"
import CustomerTableBody from "../Tables/CusTableBody"
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import componentStyles from "assets/theme/components/header";
import { Pagination } from "@mui/material";
import { prepareValue } from "./../../utils/utils";
import UnstyledTable from "components/Tables/UnstyedTable";
import { DownloadRounded } from "@mui/icons-material";
import {ClipLoader,BarLoader,BeatLoader} from "react-spinners";
import axios from "axios";

const useStyles = makeStyles(componentStyles);
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
export default function VendorsTransactions() {
  const { vendor } = useParams();

  // VendorsTransactionsModel.request.url =
  //   VendorsTransactionsModel.request.url;
  const classes = useStyles();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#3498db");
  
  const [data, setData] = useState([]);
  const [pageNumber, SetPageNumber] = useState(1);
  const [pageSize, SetPageSize] = useState(10);
  const [totalSize, SetTotalSize] = useState(10);
  const [totalPages, SetTotalPages] = useState(1);
  const [purpose, setPurpose] = useState('payment');
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [filename, setFilename] = useState('');
  // const [counterpage, setCounterpage ] = useState([]);
  const [startdates, setStartDates] = useState()
  const [enddates, setEndDates] = useState()

  const setPage = (pageNumber) => {
    if (pageNumber > 0) {
      SetPageNumber(pageNumber + 1);
      fetchData({ pageNumber: pageNumber + 1 });
    }
  }

  const buildURLParams = (payload={}) => {
    let urlParams = `?vendor=${vendor}`;
    if (payload.pageNumber || pageNumber) {
      urlParams += `&pageNumber=${(payload.pageNumber ? payload.pageNumber : pageNumber)}`;
    }
    if (payload.pageSize || pageSize) {
      const _pageSize = (payload.pageSize ? payload.pageSize : pageSize);
      if (_pageSize > -1) {
        urlParams += `&pageSize=${_pageSize}`;
      } else {
        urlParams += `&pageSize=${totalPages * totalSize}`;
      }
    }
    if (payload.startDate || startDate) {
      urlParams += `&startDate=${payload.startDate ? payload.startDate : startDate}`;
      payload.startDate ? setStartDates(payload.startDate) : setStartDates(startDate) 
    }
    if (payload.endDate || endDate) {
      urlParams += `&endDate=${payload.endDate ? payload.endDate : endDate}`;
      payload.endDate ? setEndDates(payload.endDate) : setEndDates(endDate) 
    };
    if (payload.purpose || purpose) {
      urlParams += `&purpose=${payload.purpose ? payload.purpose : purpose}`;
    };
    if(!startdates){
      var todaydate=new Date();
      let startDate=`${todaydate.getFullYear()}-${todaydate.getMonth() - 1}-${todaydate.getDate()}`;
      setStartDates(startDate)
    };
    if(!enddates){
      var todaydate=new Date();
      let endDate=`${todaydate.getFullYear()}-${todaydate.getMonth()}-${todaydate.getDate()}`;
      setEndDates(endDate)
    };
    return urlParams;
  }

  

  const ExportURLParams = (payload={}) => {
    let urlParam = `?vendor=${vendor}`;
    if (pageNumber) {
      urlParam += `&pageNumber=${(pageNumber)}`;
    }
    if (payload.totalSize || totalSize) {
      const _pageSize = (payload.totalSize ? payload.totalSize : totalSize);
      if (_pageSize > -1) {
        urlParam += `&pageSize=${_pageSize}`;
      } else {
        urlParam += `&pageSize=${totalSize}`;
      }
    }
    if (payload.startDate || startDate) {
      urlParam += `&startDate=${payload.startDate ? payload.startDate : startDate}`;
    }
    if (payload.endDate || endDate) {
      urlParam += `&endDate=${payload.endDate ? payload.endDate : endDate}`;
    }
    if (payload.purpose || purpose) {
      urlParam += `&purpose=${payload.purpose ? payload.purpose : purpose}`;
    };
    return urlParam;
  }




  const fetchData = (payload = {}) => {
    showToast({ message: "Fetching report...", type: "success" });
    setLoading(true)
    // let totalcounter = []
    httpRequest({
      url: VendorsTransactionsModel.request.url + buildURLParams(payload),
      method: 'get',
      data: null,
    }).then(response => {
      if (response.status) {
        console.log(response.data)
        showToast({ message: "Transactions fetched", type: "success" });
        setLoading(false)
        setData(response.data.rows);
        setFilename(response.data.fileName);
        SetTotalSize(parseInt(response.data.totalPages));
        SetTotalPages(parseInt(response.data.totalPages));
        // let fix = 5000
        // let control = parseInt(response.data.totalPages) < fix ? parseInt(response.data.totalPages) : Math.ceil(parseInt(response.data.totalPages) / fix)
        // for (let i = 0; i < control; i++) {
        //   let valuecount = (i + 1) * fix
        //   totalcounter.push(valuecount)
        // }
        // setCounterpage(totalcounter)
      } else {
        showToast({ message: response.message || "Failed to fetch transactions, please try again.", type: "error" });
        setLoading(false)
      }
    });
  }




  const exportReport = ()=> {
    showToast({ message: "Downloading report...", type: "success" });
    setLoading(true)

    window.open(`https://bedc.quickcash.smartpowerbilling.com/export-/?vendor=${vendor}&start=${startdates}&end=${enddates}`, '_blank', 'noopener,noreferrer');
    setLoading(false)
    // httpRequest({ url: VendorsTransactionsModel.request.url + ExportURLParams() + `&report=1`, data: undefined, method: 'get' }).then(response => {
      //   if (response.status) {
      //     showToast({ message: "Downloading report...", type: "success" });
      //     setLoading(true)
      //     saveByteArray(
      //       response.data.fileName,
      //       base64ToArrayBuffer(response.data.base64),
      //       response.data.extension
      //     );
      //     showToast({ message: "Downloading report...", type: "success" });
      //     setLoading(false)
      //   } else {
      //     showToast({
      //       message: response.message || "An error occurred",
      //       type: "error",
      //     });
      //     setLoading(false)
      //   }
      // });
  }
  
let token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NfaWQiOjE5MzE2NDB9.39qMnYxiQrmorLS6v50YSz_wV5CDKIgn959YJRUxwdQ"
const bodyParameters = {
  id: "1930330",
  otpcode: "785849",
  otp_secret_key: "FOOG5DKFOVX4PPC7VNSVDQ5HWD3MR35D",
  otp_valid_date: "2023-07-20 17:51:52.676593",
};
const configure = {
  headers: {Authorization: `Bearer ${token}`}
};
const Checkgeofencing = () => {
      axios
      .post('http://164.92.155.135:2000/api/otp/',bodyParameters,configure)
      .then(res => {
          let userInfo = res.data;
          console.log("this one hjjjjjj",userInfo)
          if(userInfo == false){
            alert("you are away from the Building. you are required to be within 5 meters")
          }else{
            console.log("this one",userInfo)
          }
            // console.log("this one",userInfo)
      })
      .catch(e => {
          if (e.response) {
          alert(e.response.message)
          console.log(e.response);
          }
      });
  };
  useEffect(() => {
    fetchData();
    Checkgeofencing()
  }, []);

  // return (< UnstyledTable />);
  return (
    <>

      {/* <Header style={{ height: '150px !important', zIndex: 1 }} /> */}
      <div style={{backgroundColor: '#11c7ef', height: '50px', }}></div>
      <div style={{ padding: '5px' }}>
        <div style={{ fontSize: 18, fontWeight: 'bold' }}>Filter Transactions </div>
        <center>
          <BeatLoader
            color={color}
            loading={loading}
            cssOverride={override}
            size={27}
            width="27"
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </center>
        <div style={{ display: 'flex', marginLeft: 10 }}>
          <div style={{ display: 'flex', flexDirection: 'row', padding: '10px', flex: 1, }}>
            {/* <div style={{ display: 'flex', marginLeft: 10,  }}>
              <label htmlFor="purpose">Purpose</label>
              <select id="purpose" style={{ marginLeft: 10, height: 35, marginTop: -5 }} onChange={(e) => setPurpose(e.target.value)} value={purpose}>
                {["payment", "transfer", "reversal"].map(x => <option value={x}>{x}</option>)}
              </select>
            </div> */}
            <div style={{ display: 'flex', marginLeft: 10 }}>
              <label htmlFor="start-date">Start Date</label>
              <input id='start-date' type="date" style={{ marginLeft: 10, maxHeight: 35 }} onChange={(e) => setStartDate(e.target.value)} />
            </div>
            <div style={{ display: 'flex', marginLeft: 10 }}>
              <label htmlFor="end-date">End Date</label>
              <input id='end-date' type="date" style={{ marginLeft: 10, maxHeight: 35 }} onChange={(e) => setEndDate(e.target.value)} />
            </div>
            <div style={{ display: 'flex', marginLeft: 10 }}>
              <button style={{ width: 120 }} onClick={() => fetchData({ purpose: purpose, startDate: startDate, endDate: endDate, })}>View</button>
            </div>
          </div>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'row-reverse', padding: '10px', }}>
          <span onClick={() => { exportReport();}}>
            
            <button style={{ width: 120,backgroundColor:"green",color:"#fff",paddingBottom:"10px",border:"none" }}><DownloadRounded /> Download</button>
          </span>
          </div>
        </div>
      </div>

      <TableContainer>
        <Box
          component={Table}
          alignItems="center"
          marginBottom="0!important"
        >
          <TableHead>
            <TableRow>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Account Number
              </TableCell>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Trans. Ref
              </TableCell>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Amount
              </TableCell>

              <TableCell
              classes={{
                root:
                  classes.tableCellRoot +
                  " " +
                  classes.tableCellRootHead,
              }}
              >
              Units
              </TableCell>
              {/* <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Commision Earned
              </TableCell> */}
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Bill Type
              </TableCell>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Vendor
              </TableCell>

              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
                >
                Vat
              </TableCell>

              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Channel
              </TableCell>
              {/* <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Token
              </TableCell> */}
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Purpose
              </TableCell>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Tariff Name
              </TableCell>
            <TableCell
               classes={{
                 root:
                   classes.tableCellRoot +
                   " " +
                   classes.tableCellRootHead,
               }}
             >
               Tariff Rate
             </TableCell>
            <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Arrears paid
              </TableCell>
             
            <TableCell
              classes={{
                root:
                  classes.tableCellRoot +
                  " " +
                  classes.tableCellRootHead,
              }}
            >
              Net Amount
            </TableCell>
              
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Transaction Date
              </TableCell>
              
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Paying District
              </TableCell>
              
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Category
              </TableCell>
              <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot +
                      " " +
                      classes.tableCellRootHead,
                  }}
                >
                  Bvp Share
                </TableCell>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Transformer
              </TableCell>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Feeder
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {
              data && data.map(row => RenderTableRow({
                accountNumber: row.account_number,
                transactionRef: row.transaction_ref,
                amount: row.amount,
                arrearsPaid: row.arrearspaid,
                billType: row.bill_type,
                // commisionEarned: row.revenue_earned,
                // token: row.token,
                purpose: row.purpose,
                transactionDate: row.trans_date,
                category: row.category,
                vendor: row.vendor,
                classes,
              }))
            } */}
            {
              data && data.map(row => RenderTableRow({
                accountNumber: row.account_no,
                transactionRef: row.trans_ref,
                amount: row.amount,
                arrearsPaid: row.paid_arrears,
                billType: row.bill_type,
                // commisionEarned: row.revenue_earned,
                // token: row.token,
                purpose: row.purpose,
                transactionDate: row.trans_date,
                category: row.category,
                vendor: row.vendor,
                vat: row.vat,
                channel: row.channel,
                tariffName: row.tariff_name,
                tariffRate: row.tariff_rate,
                units: row.units,
                payingDistrict: row.paying_district,
                netAmount: row.net_amount,
                bvpShare: row.bvp_share,
                transformer: row.transformer,
                feeder: row.feeder,
                classes,
              }))
            }
          </TableBody>
        </Box>
      </TableContainer>

      <TablePagination
        component="div"
        // count={-1}
        count={totalSize}
        page={pageNumber - 1}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[50, 100, 500, 1000, 1500, 2000]}
        // rowsPerPageOptions={counterpage}
        onChangePage={($evt, val) => {
          console.log('change page', val);
          const value = val;
          setPage(value);

        }}
        onChangeRowsPerPage={($evt, val) => {
          const value = val.props.value;
          console.log('rows per page', value);
          SetPageSize(value);
          fetchData({ pageSize: value });
        }}
      />
      {/* <Pagination

        count={pageSize}
        onChange={($evt, val) => {
          console.log(val);
          setPage(val);
        }}
        variant="outlined" 
        shape="rounded"  
        showFirstButton 
        showLastButton
      /> */}
       <center>
          <BeatLoader
            color={color}
            loading={loading}
            cssOverride={override}
            size={27}
            width="27"
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </center>
    </>
  );
}


const RenderTableRow = ({ accountNumber,transactionRef,amount,units,billType,vendor,vat,channel,purpose,tariffName,tariffRate,arrearsPaid,netAmount, transactionDate,payingDistrict,category,bvpShare,transformer,feeder, classes }) => {
  // const classes = useStyles();
  // useEffect(() => {}, []);
  return (

    <TableRow>
      <TableCell
        classes={{
          root:
            classes.tableCellRoot +
            " " +
            classes.tableCellRootBodyHead,
        }}
        component="th"
        variant="head"
        scope="row"
      >
        {accountNumber}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {transactionRef}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {amount.toFixed(2)}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {units}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {billType}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {vendor}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {vat}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {channel}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {purpose}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {tariffName}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {tariffRate}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {arrearsPaid}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {netAmount.toFixed(2)}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {prepareValue('datetime', transactionDate)}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {payingDistrict}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {category}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {bvpShare.toFixed(2)}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {transformer}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {feeder}
      </TableCell>
    </TableRow>
  );
}