const KeyChangeToken = {
  edit: false,
  table: "res_partner",
  control: "meter_action",
  fields: [
    // {
    //   name: "meter_no",
    //   type: "select_db_auto",
    //   sort: "meter_no",
    //   fullWidth: true,
    //   table: "meter_details",
    //   value: "meter_no",
    //   field: "concat(id,' - ', meter_no)",
    //   label: "Select Meter",
    //   picks: [],
    // },
    {
      name: "field",
      type: "hidden",
      value: "",
    },
    {
      name: "value",
      type: "hidden",
      value: "",
    },
    {
      name: "ststi",
      type: "text",
      fullWidth: true,
      label: "STSTI",
      value: "",
    },
    {
      name: "to_ststi",
      type: "text",
      fullWidth: true,
      label: "To STSTI",
      value: "",
    },
    {
      name: "krn",
      type: "text",
      fullWidth: true,
      label: "KRN",
      value: "",
    },
    {
      name: "to_krn",
      type: "text",
      fullWidth: true,
      label: "To KRN",
      value: "",
    },
    {
      name: "sgc",
      type: "text",
      fullWidth: true,
      label: "SGC Code",
      value: "",
    },
    {
      name: "to_sgc",
      type: "text",
      fullWidth: true,
      label: "To SGC Code",
      value: "",
    },
    {
      name: "lifenotes",
      type: "text",
      validate: { required: true },
      fullWidth: true,
      label: "Reason",
    },
    {
      name: "action",
      type: "hidden",
      value: "keychange",
    },

    {
      name: "type",
      type: "select",
      fullWidth: true,
      label: "Keychange Type",
      picks: [
        { id: "0", text: "TI", selected: false },
        { id: "1", text: "KRN", selected: false },
      ],
      validate: { required: true },
    },
  ],
  desc: "Key Change Power",
  title: "Key Change Setup",
};

export default KeyChangeToken;
