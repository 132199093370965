// import { Add } from "@material-ui/icons";

import { DownloadRounded } from "@mui/icons-material";
import axios from "axios";
import { showToast } from "utils/utils";

export const InternalVendorsModel = Object.assign(
  
  {},
  
  {
    control: "internal_vendors_model",
    table: "",
    fabs: {
      downloadReport: {
        icon: <DownloadRounded />,
        onClick: () => {},
      },
    },
    
    action: {
      
      component: [
        // ({ data, history }) => {
        //   return (
        //     <span
        //       style={{ color: "green", cursor: "pointer" }}
        //       onClick={() => {
        //         history.push(`/admin/vendor/action/${data["username"]}`, {
        //           pathname: `/admin/vendor/action/${data["username"]}`,
        //           state: data,
        //         });
        //       }}
        //     >
        //       Fund{" "}
        //     </span>
        //   );
        // },
        
        ({ data, history }) => {
          return (
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                history.push(`/admin/vendor/transactions/${data["username"]}`, {
                  pathname: `/admin/vendor/transactions/${data["username"]}`,
                  state: data,

                });
              }}
            >
              View transactions
            </span>
          );
        },
        ({ data, history }) => {
          return (
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                // history.push(`/admin/vendor/transactions/${data["username"]}`, {
                //   pathname: `/admin/vendor/transactions/${data["username"]}`,
                //   state: data,

                // });
                  let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InRlc3Rfb3BlcmF0b3IiLCJpYXQiOjE2NjE5NTg0NTl9.itAiXaTOTdzrZK3Y7ERjLRqRhyJSg40rDx2fRjVa2AA"
                
                  const configure = {
                    headers: {Authorization: `Bearer ${token}`}
                  };
                  const totalcreadit = () => {
                        axios
                        .get(`https://bedc.spiapi.smartpowerbilling.com/report/credittransactions/?user=${data["username"]}`,configure)
                        .then(res => {
                            let userInfo = res.data.data.fileName;
                            console.log("this one",userInfo)
                            if(!userInfo){
                              showToast({
                                message: "there is no record" || "An error occurred",
                                type: "error",
                              });
                              // alert("there is no record")
                            }else{
                              window.open(`https://bedc.spiapi.smartpowerbilling.com/downloads/${userInfo}.xlsx`, '_blank', 'noopener,noreferrer');
                            }
                        })
                        .catch(e => {
                            if (e.response) {
                            // alert(e.response.data.message)
                            showToast({
                              message: e.response.data.message || "An error occurred",
                              type: "error",
                            });
                            console.log(e.response.data.message);
                            }
                        });
                  };

                  totalcreadit()
              }}
            >
              Download Total Credit Received
            </span>
          );
        },
      ],
    },
    fields: [
      {
        name: "username",
        label: "Username",
        // link: "/admin/vendor/transactions/",
        // vendorLink: "/admin/vendor/transactions/",
        // type: "selflink",
      },
      {
        name: "name",
        label: "Name",
      },
      {
        name: "totalLimitAmount",
        label: "Total Credit received",
        transform: 'currency',
        // link: `/admin/vendor/transactions/`,
        // vendorLink: "/admin/vendor/transactions/",
        //link: "/admin/vendor/action/",
        // type: "selflink",
        
      },
      // {
      //   name: "totalLimitAmount",
      //   label: "Limit Amount",
      //   transform: 'currency',
      // },
      {
        name: "userLimitAmount",
        label: "Amount Available",
        transform: 'currency',
      },
      {
        name: "amountCollected",
        label: "Amount Collected",
        transform: 'currency',
      },
      {
        name: "fees",
        label: "Fees",
        transform: 'currency',
      },
      {
        name: "amountToSettle",
        label: "Amount To Settle",
        transform: 'currency',
      },
      {
        name: "user_type",
        label: "Vendor Type",
      },
      // {
      //   name: "status",
      //   label: "Status",
      // },
      // {
      //   name: "owner_user",
      //   label: "Owner",
      // },
      {
        name: "create_date",
        label: "Created on",
        transform: "date",
      },
    ],
  }
);
