import { useTheme } from "@material-ui/core/styles";
import { Box, makeStyles } from "@material-ui/core";
import { InsertChartOutlined, ListAltOutlined } from "@material-ui/icons";
import { useEffect, useState } from "react";
import CardStats from "../../components/Cards/CardStats.js";
import componentStyles from "assets/theme/components/header.js";
import { GetTotalVending, GetTotalMinimumVending } from "../../utils/apis.js";
import { prepareValue } from "utils/utils.js";
import { extractValue } from "utils/utils.js";

const useStyles = makeStyles(componentStyles);

export const Vending = ({ footerSubtitle, form, condition }) => {
  const theme = useTheme();
  const [value, setValue] = useState(form.placeholder);
  const [values, setValues] = useState(form.placeholder);
  const [_footerSubtitle, setFooterSubtitle] = useState(footerSubtitle);
  const [_footerSubtitles, setFooterSubtitles] = useState(footerSubtitle);

  useEffect(() => {
    fetchData();
    fetchMinimuData();
    
  }, []);



  const fetchMinimuData = () => {
    GetTotalMinimumVending({
      where: condition,
    })
      .then((recs) => {
        console.log('this dd', recs)
        // 'id.arrears#prepaid_trans#acc_no'
        if (recs && recs.length > 0) {
          console.log('minimum', recs[0]["minimumVend"])
          setValue(prepareValue(form.transform, recs[0]["minimumVend"]));
          setFooterSubtitle(prepareValue("tofixed", recs[0]["minimumVend"]));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const fetchData = () => {
    GetTotalVending({
      where: condition,
    })
      .then((recs) => {
        // 'id.arrears#prepaid_trans#acc_no'
        if (recs && recs.length > 0) {
          if (recs[0]["amount"] && recs[0]["units"]) {
            //const _value = recs[0]['amount'];
            const _value = recs[0][extractValue(form.fields[0].name)];
            setValues(prepareValue(form.transform, _value));
            //prepare value for date and return
            setFooterSubtitles(prepareValue("tofixed", recs[0]["units"]));
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  
  // should make api call to vending apis
  // should receive response
  // should render data as approprate

  return (
    <CardStats
      subtitle={form.title || "Total Vended Trans."}
      title={process.env.REACT_APP_TRINITY_VENDED_CARD == 'minimumvend' ? `₦ ${value || "0.000"}` : `₦ ${values || "0.00"}`}
      icon={InsertChartOutlined}
      color="bgError"
      footer={
        <>
          <Box
            component="span"
            fontSize=".875rem"
            color={theme.palette.success.main}
            marginRight=".5rem"
            display="flex"
            alignItems="center"
          >
            <Box
              component={ListAltOutlined}
              width="1.5rem!important"
              height="1.5rem!important"
            />{" "}
          </Box>
          <Box component="span" whiteSpace="nowrap">
          {process.env.REACT_APP_TRINITY_VENDED_CARD == 'minimumvend' ? `${_footerSubtitle || 0} Vend` : `${_footerSubtitles || 0} kwH Vended`}
            {/* {`${_footerSubtitles || 0} kwH Vended`} */}
          </Box>
        </>
      }
    />
  );
};
