import {
  base64ToArrayBuffer,
  PrepareData,
  saveByteArray,
  showToast,
} from "./utils";
import { getToken } from "./localStorage.util";

import assert from "assert";
// import { Console } from "console";

let host = "188.166.99.136:5000";
export const baseHost =
  `${process.env.REACT_APP_BaseHost}` || "http://188.166.99.136:8001";

// export const baseHost = "http://188.166.99.136:8001";
//const baseHost = "http://localhost:8001";
//pass: 7rRLej4FeAZnbxq

export default class ApiClass {}


const assertAuthorized = ({ status }) => {
  if (status === 401) {
    showToast({
      message: "Invalid login, Please login again.",
      type: "error",
    });
    setTimeout(() => {
      window.location.href = "/web-login/";
    }, 1500);
    return false;
  }
  return true;
};

export async function updateDb(response, alt) {
  let url = "/customization/update";
  if (alt === false) url = "/other/updates";
  if (alt === "special") url = "/create/updates";
  let retr = null;
  //let host = "188.166.99.136:8001";
  let host = baseHost;
  console.log("generateHeader");
  await fetch(`${host}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
      ...generateHeader(),
    },
    body: JSON.stringify(response),
  })
    .then((res) => {
      if (res.status === 200) {
        if (alt === true) alert("Update was successful");
        console.log("Complete");
        retr = true;
      } else {
        assert.equal(assertAuthorized({ status: res.status }), true);
        retr = false;
      }
    })
    .catch((err) => {
      console.error(err);
      retr = false;
      // raiseError(err)
    });

  return retr;
}

export async function CreateinDb(response, alt) {
  let url = "/app/create";

  //let host = "http://188.166.99.136:8001";
  let host = baseHost;
  let retr = null;
  await fetch(`${host}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
      ...generateHeader(),
    },
    body: JSON.stringify(response),
  })
    .then((res) => {
      if (res.status === 200) {
        if (alt === true) console.log("Complete");
        retr = true;
      } else {
        assert.equal(assertAuthorized({ status: res.status }), true);
        retr = false;
      }
    })
    .catch((err) => {
      console.error(err);
      retr = false;
      raiseError(err);
    });

  return retr;
}

export async function FullCustomization(data) {
  await JuiceCreation(data);
  console.log(data);
  await JuiceUpdateOthers(data);
  await JuiceUpdateFT(data);
  await MeterAssignment(data);
  return data;
}

export function raiseError(err) {
  alert(err);
}

export async function JuiceCreation(data) {
  // JUICE CUSTOMER CREATION
  let url = "/juice/create/";
  if (data["juice_acc_no"] !== null) {
    alert("Account Already created in juice");
    return;
  }

  let account = {
    name: data.name,
    address: data.address,
    city: data.district.replace(" DISTRICT", "").toUpperCase(),
    state: data.state,
    phone: data.phone,
  };
  await fetch(`http://${host}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
      ...generateHeader(),
    },
    body: JSON.stringify(account),
    //   mode:'no-cors',
    referrerPolicy: "no-referrer",
  })
    .then((res) => {
      assert.equal(assertAuthorized({ status: res.status }), true);
      return res.json();
    })
    .then((json) => {
      if ("message" in json) {
        raiseError(json.message);
      } else {
        let result = { ...json };
        data["locid"] = result["locid"];
        data["juice_acc_no"] = result["juice_acc_no"];
        result["write_uid"] = data["write_uid"];
        console.log(result);
        result["id"] = data.id;
        updateDb(result, true);
      }
    })
    .catch((err) => {
      console.error(err);
      raiseError(err);
    });
}

export async function MeterAssignment(m_data) {
  // JUICE METER ASSIGN

  if (m_data.meter_details === undefined) {
    alert("Please assign a meter.");
    return;
  }
  let meter_details = {
    locid: m_data.locid,
    serialnumbers: m_data.meter_details.meter_no,
    com: m_data.meter_details.communication,
    units: m_data.meter_details.units,
    tariff: m_data.new_tariff,
    sgc: m_data.meter_details.sgc,
    ststi: m_data.meter_details.sts_ti,
    stskrn: m_data.meter_details.stskrn,
    factor: m_data.meter_details.factor,
    phases: m_data.meter_details.phases,
    disconnect: m_data.meter_details.disconnect,
    billing: "prepaid",
  };
  let url = "/meter/create/";

  await fetch(`http://${host}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
      ...generateHeader(),
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(meter_details),
  })
    .then((res) => {
      assert.equal(assertAuthorized({ status: res.status }), true);
      return res.json();
    })
    .then((json) => {
      if ("message" in json) {
        raiseError(json.message);
      } else {
        let result = { ...json };

        console.log(result);
        result["id"] = m_data.id;
        result["write_uid"] = m_data["write_uid"];
        updateDb(result, true);
      }
    })

    .catch((err) => {
      console.error(err);
      raiseError(err);
    });
}


export async function JuiceUpdateOthers(data) {
  // JUICE Other UPDATES
  let details = {
    params: [
      {
        locid: data.locid,
        account: data.juice_acc_no,
        locdistrict: data.district.replace(" DISTRICT", ""),
        loccity: data.district.replace(" DISTRICT", ""),
        city: data.district.replace(" DISTRICT", ""),
        phone: data.phone,
        contract: data.acc_no,
      },
    ],
  };

  let url = "/juice/update/others";

  await fetch(`http://${host}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
      ...generateHeader(),
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(details),
  })
    .then((res) => {
      assert.equal(assertAuthorized({ status: res.status }), true);
      return res.json();
    })
    .then((json) => {
      if ("message" in json) {
        raiseError(json.message);
      } else {
        alert("Juice Other Update(s) was Successfull");
      }
    })
    .catch((err) => {
      console.error(err);
      raiseError(err);
    });
}

export async function JuiceUpdateFT(data) {
  // JUICE FEEDER/TRANSFORMER UPDATES

  let details = {
    params: [
      { transformer: data.transformer, feeder: data.feeder, locid: data.locid },
    ],
  };

  let url = "/juice/update/ft";

  await fetch(`http://${host}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
      ...generateHeader(),
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(details),
  })
    .then((res) => {
      assert.equal(assertAuthorized({ status: res.status }), true);
      return res.json();
    })
    .then((json) => {
      if ("message" in json) {
        raiseError(json);
      } else {
        let result = { ...json };
        console.log(result);
        result["id"] = data.id;
        alert("Juice Feeder & Transformer Update was Successfull");
        // updateDb(result)
      }
    })
    .catch((err) => {
      console.error(err);
      raiseError(err);
    });
}

export function JuiceKeyChange(data) {
  // JUICE CREATE KEY CHANGE FOR METER

  let details = {
    ststi: data.ststi,
    account: data.juice_acc_no,
    locid: data.locid,
    meter_no: data.meter_no,
  };
  let url = "/meter/keychange/";

  fetch(`http://${host}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...generateHeader(),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(details),
  })
    .then((res) => {
      if (res.status === 200) {
        let result = { ...res.json() };
        result["id"] = data.id;
        updateDb(result, true);
      } else {
        assert.equal(assertAuthorized({ status: res.status }), true);
        raiseError(res.json());
      }
    })
    .error((err) => {
      console.error(err);
      raiseError(err);
    });
}

export function CounterQuery(opt) {
  let countParams = opt;
  countParams["count"] = true;

  return LoadCustomers(opt);
}

export function LoadCustomers(opt) {
  return fetch(`${baseHost}/get/customers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
      ...generateHeader(),
    },
    body: JSON.stringify(opt),
    referrerPolicy: "no-referrer",
  })
    .then((res) => {
      assert.equal(assertAuthorized({ status: res.status }), true);
      return res.json();
    })
    .catch((err) => {
      throw err;
    });
}

export function LoginUser(userdetails) {
  return fetch(`${baseHost}/smlogin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(userdetails),
    referrerPolicy: "no-referrer",
  })
    .then((res) => {
      assert.equal(assertAuthorized({ status: res.status }), true);
      return res.json();
    })
    .catch((err) => {
      throw err;
    });
}

export function LoadSql(sql) {
  return fetch(`${baseHost}/get/table?sql=${sql}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...generateHeader(),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: "no-referrer",
  }).then((res) => {
    assert.equal(assertAuthorized({ status: res.status }), true);
    return res.json();
  });
}

export function LoadTrinity(caller, title, requester) {
  return fetch(
    `${baseHost}/get/trinity?caller=${caller}&title=${title}&requester=${requester}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...generateHeader(),
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      referrerPolicy: "no-referrer",
    }
  ).then((res) => {
    assert.equal(assertAuthorized({ status: res.status }), true);
    return res.json();
  });
}

export function LoadFromTable(table, field) {
  return fetch(`${baseHost}/get/from_table?table=${table}&field=${field}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...generateHeader(),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: "no-referrer",
  }).then((res) => {
    assert.equal(assertAuthorized({ status: res.status }), true);
    return res.json();
  });
}

export function LoadFromTableAuto(table, field, value, search, sort, opts) {
  let url = `${baseHost}/get/from_table_auto?table=${table}&field=${field}&value=${value}`;
  if (search) {
    url += `&search=${search}&sort=${sort}`;
  }

  if (opts) {
    if (opts?.caller && opts?.requester) {
      url += `&caller=${opts.caller}&requester=${opts.requester}`;
    }
    if (!sort) {
      if (opts?.sort && opts?.order) {
        url += `&sortOrder=${opts.sort}&order=${opts.order}`;
      }
    }

    if (opts?.where) {
      url += `&where=${opts.where}&order=${opts.order}`;
    }
    
  }

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...generateHeader(),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: "no-referrer",
  }).then((res) => {
    assert.equal(assertAuthorized({ status: res.status }), true);
    return res.json();
  });
}

export function FetchFromTable(data, table, where = "", limit, opts) {
  let url = `${baseHost}/fetch/records?table=${table}&where=${where}&limit=${limit}`;
  //let _queryType = "prepaid";
  const currentUrl = window.location.href;
  if (currentUrl.includes("/admin/postpaid")) {
    url += `&queryType=${"postpaid"}`;
  } else if (currentUrl.includes("/admin/index")) {
    url += `&queryType=${"prepaid"}`;
  }
  console.log('opts', opts);
  if (opts) {
    if (opts?.caller && opts?.requester) {
      url += `&caller=${opts.caller}&requester=${opts.requester}`;
    }
    if (opts?.sort && opts?.order) {
      url += `&sort=${opts.sort}&order=${opts.order}`;
    }
  }

  return fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      ...generateHeader(),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: "no-referrer",
  }).then((res) => {
    assert.equal(assertAuthorized({ status: res.status }), true);
    return res.json();
  });
}

export function QueryTableWhere(table, field, where, limit, opts) {
  let _fields = "";
  if (typeof field == "string") {
    _fields = field;
  } else {
    _fields = field.map((res) => res.name).join(",");
  }
  console.log("opts where", opts);
  let url = `${baseHost}/get/query_table_where?table=${table}&field=${_fields}&where=${where}`;
  if (limit) url += `&limit=${limit}`;

  if (opts) {
    if (opts?.caller && opts?.requester) {
      url += `&caller=${opts.caller}&requester=${opts.requester}`;
    }
    if (opts?.sort && opts?.order) {
      url += `&sort=${opts.sort}&order=${opts.order}`;
    }
  }

  return fetch(url, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      ...generateHeader(),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: "no-referrer",
  }).then((res) => {
    assert.equal(assertAuthorized({ status: res.status }), true);
    return res.json();
  });
}

export async function MeterAction(data) {
  let url = "/meters/records";
  // {action, meter_no}
  //let host = "188.166.99.136:8001";
  let host = baseHost;
  return await fetch(`${host}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...generateHeader(),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data),
  })
    .then(async (res) => {
      assert.equal(assertAuthorized({ status: res.status }), true);
      return await res.json();
    })
    .then(async (data) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
      // raiseError(err)
      return {
        status: false,
        error: "An error occurred, please try again.",
        errorObject: err.toString(),
      };
    });
}

export async function GetLastPayment(data) {
  let url = `/get/last_payment?table=${data.table}&where=${data.where}`;
  // {action, meter_no}
  //let host = "188.166.99.136:8001";
  let host = baseHost;
  return await fetch(`${host}${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...generateHeader(),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then(async (res) => {
      assert.equal(assertAuthorized({ status: res.status }), true);
      return await res.json();
    })
    .then(async (data) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
      // raiseError(err)
      return {
        status: false,
        error: "An error occurred, please try again.",
        errorObject: err.toString(),
      };
    });
}

export async function GetTotalVending(data) {
  let url = `/get/total_vending?table=${data.table}&where=${data.where}`;
  // {action, meter_no}
  //let host = "188.166.99.136:8001";
  let host = baseHost;
  return await fetch(`${host}${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...generateHeader(),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then(async (res) => {
      assert.equal(assertAuthorized({ status: res.status }), true);
      return await res.json();
    })
    .then(async (data) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
      // raiseError(err)
      return {
        status: false,
        error: "An error occurred, please try again.",
        errorObject: err.toString(),
      };
    });
  
  
}

export async function GetTotalMinimumVending(data) {
  let acc_ = data.where.replace("acc_no='", "");
  let acc_n = acc_.replace("'", "");
  // let acc_ns = data.where.slice(8, 18);
  // let acc_nb = '6516853173';
  let url = `/get/query_table_where?table=stage_api&field=total_debt&where=acc_no=${acc_n}&limit=1`
  //let url = `/cashcollect/customer/find?account_number=${acc_n}`;
  let host = baseHost;
  // let host = 'https://bedc.quickcashapi.smartpowerbilling.com'
  return await fetch(`${host}${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...generateHeader(),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then(async (res) => {
      assert.equal(assertAuthorized({ status: res.status }), true);
      console.log("this ccc",res)
      return await res.json();
    })
    .then(async (data) => {
      console.log("this ppp",data)
      return data;
    })
    .catch((err) => {
      console.error(err);
      // raiseError(err)
      return {
        status: false,
        error: "An error occurred, please try again.",
        errorObject: err.toString(),
      };
    });
  
  
}

export async function GetTransactionHistory(data, opts={}) {
  if (!opts || !opts.type) opts.type = 'prepaid';
  let url = opts.type === 'prepaid' ? `/get/prepaid_transactions?`: `/get/postpaid_transactions?`;

  if (data["caller"] && data["requester"]) {
    url += `caller=${data.caller}&requester=${data.requester}`;
  }

  if (data.search) url += `&where=${data.search}`;
  if (data.limit) url += `&limit=${data.limit}`;

  if (opts) {
    if (opts?.sort && opts?.order) {
      url += `&sort=${opts.sort}&order=${opts.order}`;
    }
  }

  // {action, meter_no}
  //let host = "188.166.99.136:8001";
  let host = baseHost;
  return await fetch(`${host}${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...generateHeader(),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then(async (res) => {
      assert.equal(assertAuthorized({ status: res.status }), true);
      return await res.json();
    })
    .then(async (data) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
      // raiseError(err)
      return {
        status: false,
        error: "An error occurred, please try again.",
        errorObject: err.toString(),
      };
    });
}

export async function GetWalletTransactionHistory(data) {
  let url = "";
  if (data.caller && data.requester) {
    url = `/get/wallet_transactions?caller=${data.caller}&requester=${data.requester}`;
  } else {
    url = `/get/wallet_transactions`;
  }

  // {action, meter_no}
  //let host = "188.166.99.136:8001";
  let host = baseHost;
  return await fetch(`${host}${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...generateHeader(),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then(async (res) => {
      return await res.json();
    })
    .then(async (data) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
      // raiseError(err)
      return {
        status: false,
        error: "An error occurred, please try again.",
        errorObject: err.toString(),
      };
    });
}

export async function GetCustomerDetails(caller, requester) {
  let url = "";
  url = `/get/customer_details?caller=${caller}&requester=${requester}`;

  // {action, meter_no}
  //let host = "188.166.99.136:8001";
  let host = baseHost;
  return await fetch(`${host}${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...generateHeader(),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then(async (res) => {
      return await res.json();
    })
    .then(async (data) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
      // raiseError(err)
      return {
        status: false,
        error: "An error occurred, please try again.",
        errorObject: err.toString(),
      };
    });
}

export async function GetCustomerConsumption(data) {
  let url = "";
  if (data.caller && data.requester) {
    url = `/get/customer_consumption?caller=${data.caller}&requester=${data.requester}`;
  } else {
    url = `/get/customer_consumption`;
  }

  // {action, meter_no}
  //let host = "188.166.99.136:8001";
  let host = baseHost;
  return await fetch(`${host}${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then(async (res) => {
      return await res.json();
    })
    .then(async (data) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
      // raiseError(err)
      return {
        status: false,
        error: "An error occurred, please try again.",
        errorObject: err.toString(),
      };
    });
}

export async function GenerateReport(type, data, opts = {}) {
  let url = "";
  switch (type) {
    case "generate_bill":
      url = `/report/generate_bill/`;
      break;
    case "report":
      url = `/report/generate`;
      break;
    default:
      break;
  }
  if (data.caller && data.requester) {
    url = `${url}?caller=${data.caller}&requester=${data.requester}`;
  }

  // {action, meter_no}
  //let host = "188.166.99.136:8001";
  let host = baseHost;
  const payload = {
    method: opts.method || "GET",
    headers: {
      "Content-Type": "application/json",
      ...generateHeader(),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  if (opts.method && opts.method.toLowerCase() !== "get") {
    payload["body"] = PrepareData(data);
  }

  return await fetch(`${host}${url}`, payload)
    .then(async (res) => {
      return await res.json();
    })
    .then(async (data) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
      // raiseError(err)
      return {
        status: false,
        error: "An error occurred, please try again.",
        errorObject: err.toString(),
      };
    });
}

export async function FileUpload(data) {
  let url = "/file-upload";

  // {action, meter_no}
  //let host = "188.166.99.136:8001";

  //

  let host = baseHost;
  return await fetch(`${host}${url}`, {
    method: "POST",
    headers: { ...generateHeader() },
    body: PrepareData(data, "multipart"),
  })
    .then(async (res) => {
      return await res.json();
    })
    .then(async (data) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
      // raiseError(err)
      return {
        status: false,
        error: "An error occurred, please try again.",
        errorObject: err.toString(),
      };
    });
}

export async function AssignMeter(data) {
  let url = "/assign/meter";
  console.log("data", data);
  let host = baseHost;
  return await fetch(`${host}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...generateHeader(),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: PrepareData(data, "json"),
  })
    .then(async (res) => {
      return await res.json();
    })
    .then(async (data) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
      // raiseError(err)
      return {
        status: false,
        error: "An error occurred, please try again.",
        errorObject: err.toString(),
      };
    });
}

const generateHeader = () => {
  return {
    Authorization: `Bearer ${getToken()}`,
  };
};

export async function httpRequest({ url, data, method, baseUrl, contentType="json" }) {
  //let url = '/assign/meter';
  console.log("data", data);
  if (!baseUrl) baseUrl = baseHost + "/";
  let host = baseUrl;
  let _contentType = contentType === "json" ? "application/json" : contentType;
  const requestOptions = {
    method: method,
    headers: {},
  };

  if (contentType === "json") {
    requestOptions.headers["Content-Type"] = "application/json";
  }

  if (data?._headers) {
    requestOptions.headers = { ...requestOptions.headers, ...data._headers };
    delete data._headers;
  } else {
    requestOptions.headers = { ...requestOptions.headers, ...generateHeader() };
  }
  console.log("requestOptions", requestOptions);

  if (data && contentType === "json") {
    requestOptions["body"] = PrepareData(data, "json");
  } else {
    requestOptions["body"] = data;
  }

  return await fetch(`${host}${url}`, requestOptions)
    .then(async (res) => {
      return await res.json();
    })
    .then(async (data) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
      // raiseError(err)
      return {
        status: false,
        error: "An error occurred, please try again.",
        errorObject: err.toString(),
      };
    });
}

export async function downloadBillInvoiceRequest({
  url,
  data,
  method,
  fileName,
}) {
  //let url = '/assign/meter';
  console.log("data", data);
  const requestOptions = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      ...generateHeader(),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  if (data) requestOptions["body"] = PrepareData(data, "json");

  await fetch(`${url}`, requestOptions)
    .then(async (res) => {
      return await res.text();
    })
    .then((data) => {
      console.log("data res", data);
      const byteArray = base64ToArrayBuffer(data);
      saveByteArray(fileName, byteArray);
    })
    .catch((err) => {
      console.error(err);
      return {
        status: false,
        error: "An error occurred, please try again.",
        errorObject: err.toString(),
      };
    });
}

export function downloadFile(text, fileType, fileName, a) {
  var blob = new Blob([text], { type: fileType });

  a.download = fileName;
  a.href = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.dataset.downloadurl = [fileType, link.download].join(":");

  link.href = URL.createObjectURL(blob);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  setTimeout(function () {
    URL.revokeObjectURL(link.href);
  }, 1500);
}

export async function generatePDFData({ url, method, fileName }) {
  //return new Promise(async (resolve, reject) => {
  try {
    const requestOptions = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        ...generateHeader(),
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    await fetch(`${url}`, requestOptions)
      .then((res) => res.text())
      .then((response) => {
        try {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${fileName}${new Date().toISOString()}.pdf`
          );
          link.setAttribute("target", `_blank`);

          document.body.appendChild(link);
          link.click();
          return response;
          //resolve(response);
        } catch (err) {
          return err;
          //reject (err);
        }
      });
  } catch (error) {
    console.log("error", error);
  }
}
