/* eslint-disable unused-imports/no-unused-imports */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { ListAltOutlined } from "@mui/icons-material";
// @material-ui/icons components
// import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import InsertChartOutlined from "@material-ui/icons/InsertChartOutlined";
import PieChart from "@material-ui/icons/PieChart";
import { TableChart } from "@material-ui/icons";
import Badge from "@mui/material/Badge";

// core components
import CardStats from "components/Cards/CardStats.js";

import componentStyles from "assets/theme/components/header.js";
import { AutoformRenderCard } from "./AutoFormRenderCard";
import {
  WalletSingleModel,
  ArrearsSingleModel,
  PrepaidTransactionSingleModel,
  LastPaymentSingleModel,
  TotalVendModel,
  SubaccountSingleModel,
  MinimumSingleModel,
  //TotalVendModel
} from "../model/trinity_cardstat_model";
import { PaymentCard } from "./PaymentCard";
import { Vending } from "./VendingCard";

const useStyles = makeStyles(componentStyles);

const TrinityHeader = ({ caller, requester, billStatus }) => {
  const classes = useStyles();
  const theme = useTheme();
  const _caller = caller === "meter" ? "meter_no" : caller;

  console.log("opts PrepaidTransactionSingleModel", PrepaidTransactionSingleModel.opts);

  return (
    <>
      <div className={classes.header}>
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <div>
            <Grid container>
              <Grid item xl={3} lg={6} xs={12}>
                <AutoformRenderCard
                  condition={`${_caller}='${requester}'`}
                  form={WalletSingleModel}
                  prefix={"NGN "}
                  key={new Date().toLocaleTimeString()}
                />

                {/* <Badge  
                  badgeContent="Vending" 
                  title="vend"
                  color="success" 
                  style={{minWidth:"14px", marginTop:"-50%", float:"right",minHeight:"16px",maxWidth:"16px",maxHeight:"16px",borderRadius:"7px"}}>
                </Badge> */}
              </Grid>
              <Grid item xl={3} lg={6} xs={12}>
              <AutoformRenderCard
                  condition={`${_caller}='${requester}'`}
                  form={process.env.REACT_APP_TRINITY_VENDED_CARD == 'minimumvend' ? MinimumSingleModel : ArrearsSingleModel}
                  prefix={"NGN "}
                  key={new Date().toLocaleTimeString()}
                />
                {/* <Vending
                  condition={`${_caller}='${requester}'`}
                  form={process.env.REACT_APP_TRINITY_VENDED_CARD == 'minimumvend' ? MinimumSingleModel : TotalVendModel}
                  //form={LastPaymentSingleModel}
                /> */}
              </Grid>
              <Grid item xl={3} lg={6} xs={12}>
                <AutoformRenderCard
                  condition={`${_caller}='${requester}'`}
                  form={process.env.REACT_APP_TRINITY_ARREARS_CARD == 'subaccount' ? SubaccountSingleModel : ArrearsSingleModel}
                  prefix={"NGN "}
                  key={new Date().toLocaleTimeString()}
                />
              </Grid>
              <Grid item xl={3} lg={6} xs={12}>
                <AutoformRenderCard
                  form={PrepaidTransactionSingleModel}
                  prefix={"NGN "}
                  key={new Date().toLocaleTimeString()}
                  caller={_caller}
                  requester={requester}
                />
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
};

export default TrinityHeader;
