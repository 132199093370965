import React, { useEffect } from "react";
import TrinityHeader from "./TrinityHeader";
import componentStyles from "assets/theme/components/header.js";
import { Grid, Box, Container, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TrinityCard from "components/TrinityCard";
import { useHistory, useParams } from "react-router-dom";
import AutoForms from "./AutoForm";
import SwapMeterModel from "model/swap_meter_model";
import { triggerMenuActionController, TrinityItemSetup } from "./../controllers/trinity-item.controller";
import AutoformRenderInput from "./AutoformRenderInput";
import PopupFetchTable from "./PopupFetchTable";
import VendAccountModel, { CashVendAccountModel } from "../model/vend_account";
import { generateKey, prepareParamCaller, showToast } from "../utils/utils";
import { PreviousArrearsModel } from "./../model/autoform_renderinput_model";
import FetchTable from "./Tables/FetchTable";
import TransactionTreeModel from "../model/transactions/transactions_model";
import WalletActivitiesModel from "model/wallet_model";

const TrinityItem = () => {
  const useStyles = makeStyles(componentStyles);

  const classes = useStyles();
  const [refresh, setRefresh] = React.useState(false);
  const { caller, requester } = useParams();
  console.log("caller, requester", caller, requester);

  const _caller = prepareParamCaller(caller);
  //console.log(caller);
  //let trinities = [];

  const [trinities, setTrinities] = React.useState([]);

  const [showMenu, setShowMenu] = React.useState(false);
  const [callerAction, setCallerAction] = React.useState("");
  const [formModel, setFormModel] = React.useState(SwapMeterModel);
  const [showArrears, setShowArrears] = React.useState(false);
  const [showWalletActivies, setShowWalletActivies] = React.useState(false);
  const [showVend, setShowVend] = React.useState(false);
  const [vendType, setVendType] = React.useState("");
  const history = useHistory();

  // switch (caller) {
  //   case "locid":
  //     trinities = [
  //       {
  //         caller: caller,
  //         requester: requester,
  //         title: "Location",
  //         controls: [
  //           {
  //             name: "Swap Meter",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "swap-meter",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Install Meter",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "assign-meter",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Update Seal",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "update-seal",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //         ],
  //       },
  //       {
  //         caller: caller,
  //         requester: requester,
  //         title: "Account",
  //         controls: [
  //           // { name: "Activate 4 Vending", action: () => {
  //           //   triggerMenuActionController(
  //           //     "activate-vending",
  //           //     setShowMenu,
  //           //     setFormModel,
  //           //     requester,caller
  //           //   );
  //           // } },
  //           // {
  //           //   name: "Add Location",
  //           //   action: () => {
  //           //     triggerMenuActionController(
  //           //       "add-location",
  //           //       setShowMenu,
  //           //       setFormModel,
  //           //       requester,
  //           //       caller
  //           //     );
  //           //   },
  //           // },
  //           {
  //             name: "Assign Meter",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "assign-meter",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Wallets Activites",
  //             action: () => setShowWalletActivies(!showWalletActivies),
  //           },
  //           {
  //             name: "Arrears Lookup",
  //             action: () => setShowArrears(!showArrears),
  //           },
  //           // {
  //           //   name: "Add Tariff",
  //           //   action: () => {
  //           //     triggerMenuActionController(
  //           //       "add-tarrif",
  //           //       setShowMenu,
  //           //       setFormModel,
  //           //       requester,
  //           //       caller
  //           //     );
  //           //   },
  //           // },
  //           {
  //             name: "Move Out",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "move-out",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Move IN",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "move-in",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //         ],
  //       },
  //       {
  //         caller: caller,
  //         requester: requester,
  //         title: "Meter",
  //         controls: [
  //           {
  //             name: "Change SGC Code",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "change-sgc-code",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Change Tariff Index",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "change-tariff-index",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Remove Meter",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "remove-meter",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Set Max Power",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "max-power",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Free Issue",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "free-issue",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Vend",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "vend",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Clear Credit Token",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "clear-credit-token",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Tamper Token",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "tamper-token",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           //{ name: "Transactions", action: null },
  //         ],
  //       },
  //     ];
  //     break;
  //   case "meter":
  //     trinities = [
  //       {
  //         caller: caller,
  //         requester: requester,
  //         title: "Meter",
  //         controls: [
  //           {
  //             name: "Change SGC Code",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "change-sgc-code",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Change Tariff Index",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "change-tariff-index",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Remove Meter",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "remove-meter",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Set Max Power",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "max-power",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Free Issue",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "free-issue",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Vend",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "vend",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Clear Credit Token",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "clear-credit-token",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Tamper Token",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "tamper-token",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           //{ name: "Transactions", action: null },
  //           {
  //             name: "Key Change",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "key-change",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //         ],
  //       },
  //       {
  //         caller: caller,
  //         requester: requester,
  //         title: "Location",
  //         controls: [
  //           // {
  //           //   name: "Adding a Tariff",
  //           //   action: () => {
  //           //     triggerMenuActionController(
  //           //       "add-tariff",
  //           //       setShowMenu,
  //           //       setFormModel,
  //           //       requester,
  //           //       caller
  //           //     );
  //           //   },
  //           // },
  //           {
  //             name: "Swap Meter",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "swap-meter",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Remove Meter",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "remove-meter",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Install Meter",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "assign-meter",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Update Seal",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "update-seal",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //         ],
  //       },
  //       {
  //         caller: caller,
  //         requester: requester,
  //         title: "Account",
  //         controls: [
  //           // { name: "Activate 4 Vending", action: () => {
  //           //   triggerMenuActionController(
  //           //     "activate-vending",
  //           //     setShowMenu,
  //           //     setFormModel,
  //           //     requester,caller
  //           //   );
  //           // } },
  //           // {
  //           //   name: "Add Location",
  //           //   action: () => {
  //           //     triggerMenuActionController(
  //           //       "add-location",
  //           //       setShowMenu,
  //           //       setFormModel,
  //           //       requester,
  //           //       caller
  //           //     );
  //           //   },
  //           // },
  //           {
  //             name: "Assign Meter",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "assign-meter",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Wallets Activites",
  //             action: () => setShowWalletActivies(!showWalletActivies),
  //           },
  //           {
  //             name: "Arrears Lookup",
  //             action: () => setShowArrears(!showArrears),
  //           },
  //           // {
  //           //   name: "Add Tariff",
  //           //   action: () => {
  //           //     triggerMenuActionController(
  //           //       "add-tarrif",
  //           //       setShowMenu,
  //           //       setFormModel,
  //           //       requester,
  //           //       caller
  //           //     );
  //           //   },
  //           // },
  //           {
  //             name: "Move Out",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "move-out",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Move IN",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "move-in",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //         ],
  //       },
  //     ];

  //     break;
  //   default:
  //     trinities = [
  //       {
  //         caller: caller,
  //         requester: requester,
  //         title: "Account",
  //         controls: [
  //           // { name: "Activate 4 Vending", action: () => {
  //           //   triggerMenuActionController(
  //           //     "activate-vending",
  //           //     setShowMenu,
  //           //     setFormModel,
  //           //     requester,caller
  //           //   );
  //           // } },
  //           // {
  //           //   name: "Add Location",
  //           //   action: () => {
  //           //     triggerMenuActionController(
  //           //       "assign-location",
  //           //       setShowMenu,
  //           //       setFormModel,
  //           //       requester,
  //           //       caller
  //           //     );
  //           //   },
  //           // },
  //           {
  //             name: "Assign Meter",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "assign-meter",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Wallets Activites",
  //             action: () => setShowWalletActivies(!showWalletActivies),
  //           },
  //           {
  //             name: "Arrears Lookup",
  //             action: () => setShowArrears(!showArrears),
  //           },
  //           {
  //             name: "Add Tariff",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "add-tariff",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           // {
  //           //   name: "Payment Mode",
  //           //   action: () => {
  //           //     triggerMenuActionController(
  //           //       "payment-mode",
  //           //       setShowMenu,
  //           //       setFormModel,
  //           //       requester,caller
  //           //     );
  //           //   },
  //           // },
  //           {
  //             name: "Vending Control",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "vending-control",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Move Out",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "move-out",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Move In",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "move-in",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //         ],
  //       },
  //       {
  //         caller: caller,
  //         requester: requester,
  //         title: "Location",
  //         controls: [
  //           // {
  //           //   name: "Adding a Tariff",
  //           //   action: () => {
  //           //     triggerMenuActionController(
  //           //       "add-tariff",
  //           //       setShowMenu,
  //           //       setFormModel,
  //           //       requester,
  //           //       caller
  //           //     );
  //           //   },
  //           // },
  //           {
  //             name: "Swap Meter",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "swap-meter",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Remove Meter",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "remove-meter",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Install Meter",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "assign-meter",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Update Seal",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "update-seal",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //         ],
  //       },
  //       {
  //         caller: caller,
  //         requester: requester,
  //         title: "Meter",
  //         controls: [
  //           {
  //             name: "Change SGC Code",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "change-sgc-code",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Change Tariff Index",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "change-tariff-index",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Remove Meter",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "remove-meter",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Set Max Power",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "max-power",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Free Issue",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "free-issue",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Vend",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "vend",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Clear Credit Token",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "clear-credit-token",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Tamper Token",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "tamper-token",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           {
  //             name: "Key Change",
  //             action: () => {
  //               triggerMenuActionController(
  //                 "key-change",
  //                 setShowMenu,
  //                 setFormModel,
  //                 requester,
  //                 caller
  //               );
  //             },
  //           },
  //           //{ name: "Transactions", action: null },
  //         ],
  //       },
  //     ];
  //     break;
  // }

  const onTriggerVend = (e, state) => {
    e.preventDefault();
    if (state === "arrears") {
      history.push(`/admin/arrears-vend/${caller}/${requester}`, {
        pathname: `/admin/arrears-vend/${caller}/${requester}`,
        state: {
          caller: prepareParamCaller(caller),
          requester,
        },
      });
      return;
    } else if (state === "smartpin") {
      showToast({
        type: "error",
        message: "Feature not available",
      });
      return;
    }


    // fill field hidden field values
    if (state === "wallet") {
      VendAccountModel.fields[0].value = prepareParamCaller(caller);
      VendAccountModel.fields[1].value = requester;
      setShowVend(!showVend);
      setVendType(state);
    } else if (state === "cash") {
      // alert(state);
      // CashVendAccountModel.fields[0].value = prepareParamCaller(caller);
      // CashVendAccountModel.fields[1].value = requester;
      if (process.env.REACT_APP_IN_APP_VEND == 'false') {
        showToast({
          type: 'error',
          message: 'Feature not supported.'
        });
        return;
      } else {
        triggerMenuActionController(
          "vend",
          setShowMenu,
          setFormModel,
          requester,
          caller
        );
      }

    }
  };

  const getBillStatus = async () => { };

  useEffect(() => {
    getBillStatus();
    setTrinities(TrinityItemSetup({
      caller,
      requester,
      setFormModel,
      setShowMenu,
      opts: { setShowWalletActivies, showWalletActivies, setShowArrears, showArrears }
    }));
  }, []);

  return (
    <>
      <TrinityHeader caller={caller} requester={requester} />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          {/* <Button
            onClick={(e) => {
              onTriggerVend(e, "cash");
            }}
            style={{ marginBottom: 20, background: "white", marginLeft: 20 }}
          >

            Credit Vend
          </Button> */}
          <Button
            onClick={(e) => {
              onTriggerVend(e, "wallet");
            }}
            style={{ marginBottom: 20, background: "white", marginLeft: 20 }}
          >
            Wallet Vend
          </Button>
          {/* <Button
            onClick={(e) => {
              onTriggerVend(e, "arrears");
            }}
            style={{ marginBottom: 20, background: "white", marginLeft: 20 }}
          >
            Arrears
          </Button> */}
          {/* <Button
            onClick={(e) => {
              onTriggerVend(e, "smartpin");
            }}
            style={{ marginBottom: 20, background: "white", marginLeft: 20 }}
          >
            Smart Pin
          </Button> */}
        </Grid>
        <Grid container>
          {trinities.map((item) => {
            return (
              <TrinityCard
                key={generateKey()}
                opt={item}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            );
          })}
        </Grid>
        <AutoForms
          key={10001}
          keyId={156001}
          form={formModel}
          cancel={setShowMenu}
          open={showMenu}
          submit={() => {
            setRefresh(!refresh);
          }}
        />
        <AutoformRenderInput
          fullWidth
          handleChange={(e) => { }}
          handleClose={() => setShowArrears(false)}
          open={showArrears}
          keyId={120001}
          key={291}
          // label={'Arrears Lookup'}
          condition={`${prepareParamCaller(caller)}='${requester}'`}
          form={PreviousArrearsModel}
        />
        <PopupFetchTable
          handleClose={() => setShowWalletActivies(false)}
          open={showWalletActivies}
          keyId={12001}
          key={21}
          title={`${requester}'s Wallet Activities`}
        />
        <AutoForms
          key={10}
          keyId={101567}
          form={vendType === "wallet" ? VendAccountModel : CashVendAccountModel}
          cancel={setShowVend}
          open={showVend}
          submit={(e) => { }}
        />
      </Container>
      <FetchTable
        styles={{ marginTop: "0" }}
        settings={{
          name: "Vending Transactions",
          table: "",
          where: `${prepareParamCaller(caller)}='${requester}'`,
          limit: TransactionTreeModel.limit,
          control: TransactionTreeModel.control,
          data: {
            caller: _caller,
            requester,
          },
        }}
        columns={TransactionTreeModel.fields}
        records={[]}
        form={TransactionTreeModel}
      />
      <FetchTable
        settings={{
          name: "Wallet Transactions",
          add: () => { },
          table: "",
          where: `${caller}='${requester}'`,
          control: WalletActivitiesModel.control,
          limit: WalletActivitiesModel.limit,
          data: {
            caller,
            requester,
          },
        }}
        columns={WalletActivitiesModel.fields}
        records={[]}
      />
    </>
  );
};

export default TrinityItem;
